import React, { useEffect } from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import InsideGuidewireBlueContent from "../components/ig-blue-content"
import InsideGuidewireIntro from "../components/ig-intro"
import InsideGuidewireExpander from "../components/ig-expander"
import InsideGuidewireExplore from "../components/ig-explore"
import CentralImage from "../components/central-image"
import HeroInsideGuidewire from "../components/hero-inside-guidewire"
import Widget from "../components/widgets"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"

export interface IInsideGuidewire {
  title: string
  seoTitle: string
  seoDescription: string
  heroSubheading: string
  heroHeading: string
  heroContent: {
    heroContent: string
  }

  blueContentHeading: string
  blueContentLeftContent: {
    blueContentLeftContent: string
  }
  blueContentRightContent: {
    blueContentRightContent: string
  }

  introSubheading: string
  introHeading: string
  introContent: {
    introContent: string
  }

  centralImage: {
    fluid: object
  }

  pillars: any
  explore: any

  region?: Array<any>
}

interface IInsideGuidewireProps {
  contentfulCareersInsideGuidewire: IInsideGuidewire
}

interface ICareersInsideGuidewireContext {
  id: string
  slug: string
  locale: string
}

const InsideGuidewire: React.FC<PageProps<IInsideGuidewireProps, ICareersInsideGuidewireContext>> = ({
  pageContext,
  path,
  data,
}) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: data.contentfulCareersInsideGuidewire.seoTitle || data.contentfulCareersInsideGuidewire.title,
    description: data.contentfulCareersInsideGuidewire.seoDescription,
  }

  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substr(0, 2),
      }}
    >
      {/* Layout contains menus */}
      <Layout seoData={seoData}>
        <HeroInsideGuidewire
          subheading={data.contentfulCareersInsideGuidewire.heroSubheading}
          heading={data.contentfulCareersInsideGuidewire.heroHeading}
          content={data.contentfulCareersInsideGuidewire.heroContent}
          className="inside-guidewire"
        />
        <CentralImage image={data.contentfulCareersInsideGuidewire.centralImage} />
        <InsideGuidewireBlueContent
          heading={data.contentfulCareersInsideGuidewire.blueContentHeading}
          leftContent={data.contentfulCareersInsideGuidewire.blueContentLeftContent}
          rightContent={data.contentfulCareersInsideGuidewire.blueContentRightContent}
        />
        <InsideGuidewireIntro
          subheading={data.contentfulCareersInsideGuidewire.introSubheading}
          heading={data.contentfulCareersInsideGuidewire.introHeading}
          content={data.contentfulCareersInsideGuidewire.introContent}
          className="inside-guidewire-intro"
        />
        <InsideGuidewireExpander pillars={data.contentfulCareersInsideGuidewire.pillars} />
        <InsideGuidewireExplore items={data.contentfulCareersInsideGuidewire.explore} />

        {data.contentfulCareersInsideGuidewire.region &&
          data.contentfulCareersInsideGuidewire.region.map(widgetData => (
            <Widget className="inside-guidewire-widget" {...widgetData} />
          ))}
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default InsideGuidewire

export const query = graphql`
  query ($id: String!) {
    contentfulCareersInsideGuidewire(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      heroSubheading
      heroHeading
      heroContent {
        ...RichTextField
      }

      centralImage {
        fluid: gatsbyImageData(width: 2048, height: 1500, quality: 100, formats: [AUTO, WEBP])
        title
      }

      blueContentHeading
      blueContentLeftContent {
        ...RichTextField
      }
      blueContentRightContent {
        ...RichTextField
      }

      introSubheading
      introHeading
      introContent {
        ...RichTextField
      }

      pillars {
        ...ComponentCard
      }

      explore {
        ...ComponentCard
      }
      region {
        ...ComponentCTA
      }
    }
  }
`
