import React from "react"
import "./style.scss"
import RichTextRenderer from "../../elements/RichTextRenderer"

interface InsideGuidewireBlueContent {
  heading: string
  leftContent: any
  rightContent: any
}

const InsideGuidewireBlueContent: React.FC<InsideGuidewireBlueContent> = ({ heading, leftContent, rightContent }) => {
  return (
    <div className="blue-content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <span className="preHeading">Purpose Statement</span>
            <h2 className="h1">{heading}</h2>
            <div className="row">
              <div className="col-md-6">
                <RichTextRenderer json={leftContent} />
              </div>
              <div className="col-md-6">
                <RichTextRenderer json={rightContent} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InsideGuidewireBlueContent
