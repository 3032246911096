import React from "react"

import RichTextRenderer from "../../elements/RichTextRenderer"
import "./style.scss"

interface HeroMazeDefault {
  heading: any
  subheading?: string
  content: any
  blueMaze?: boolean
  className?: string
}

// EMPTY DIV WITH THE CLASS NAME inside-guidewire-widget-container SO SCRIPT CAN EMBED WIDGET THERE.

const HeroInsideGuidewire: React.FC<HeroMazeDefault> = ({ heading, subheading, content, className = "" }) => {
  return (
    <section className={`hero hero-default ${className}`}>
      <div className="inside-guidewire-widget-container">
        <div className="container">
          <div>
            <div />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center text-center inside-guidewire-text-container">
          <div className="col-lg-10 col-xxl-12">
            {subheading && <p className="sub-heading">{subheading}</p>}
            {typeof heading === "string" ? (
              <h1 className="heading">{heading}</h1>
            ) : (
              <RichTextRenderer json={heading} noContainer />
            )}
            {content ? (
              typeof content === "string" ? (
                <p className="content">{content}</p>
              ) : (
                <RichTextRenderer json={content} noContainer />
              )
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroInsideGuidewire
