import React from "react"
import Carousel from "../../elements/Carousel"
import RichTextRenderer from "../../elements/RichTextRenderer"
import "./style.scss"

interface InsideGuidewireExpander {
  pillars: Array<InsideGuidewireExpanderItem>
}

interface InsideGuidewireExpanderItem {
  title: string
  heading: string
  variation: string
  content: {
    content: string
  }
  image: {
    fluid: any
    title?: string
  }
  videoAsset: {
    file: {
      url: string
    }
  }
}

const InsideGuidewireExpander: React.FC<InsideGuidewireExpander> = ({ pillars }) => {
  return (
    <div className="expander" id="pin">
      <div className="mask" />
      <Carousel
        element="js-expand-carousel"
        showBullets
        showArrows
        className="js-scroll-to"
        options={{
          type: "slider",
          perView: 1,
          gap: 0,
          animationDuration: 1000,
        }}
      >
        {pillars.map(pillar => (
          <li className="glide__slide pillar-slide">
            {pillar.videoAsset ? (
              <video width="1600" height="900" muted loop autoPlay playsInline poster={pillar.image?.fluid?.src || "#"}>
                <source src={pillar.videoAsset?.file?.url || "#"} type="video/mp4" />
              </video>
            ) : (
              <img srcSet={pillar.image?.fluid?.images?.fallback?.srcSet || "#"} alt={pillar.image?.title || ""} width={1580} height={900} />
            )}
            <div className="content container">
              <div
                className={`inner ${
                  pillar.variation === "Right"
                    ? "pillar-right"
                    : pillar.variation === "Center"
                    ? "pillar-center"
                    : "pillar-left"
                }`}
              >
                <h3 className="pillar-title">{pillar.heading}</h3>
                <RichTextRenderer json={pillar.content} />
              </div>
            </div>
          </li>
        ))}
      </Carousel>
    </div>
  )
}

export default InsideGuidewireExpander
