import React from "react"
import "./style.scss"
import RichTextRenderer from "../../elements/RichTextRenderer"
import pillarsImg from "../../images/homepage-pillars.jpg"
import classNames from "classnames"

interface InsideGuidewireIntro {
  heading: string
  subheading: string
  content: any
  className?: string
}

const InsideGuidewireIntro: React.FC<InsideGuidewireIntro> = ({ heading, subheading, content, className = "" }) => {
  return (
    <div className={classNames("section-intro", className)}>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-10 col-xxl-8">
            <p className="sub-heading">{subheading}</p>
            <h2 className="h1">{heading}</h2>
            <RichTextRenderer json={content} />
          </div>
        </div>
      </div>
      <div className="graphics">
        <img src={pillarsImg} alt="Pillars"/>
      </div>
    </div>
  )
}

export default InsideGuidewireIntro
